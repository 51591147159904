import React, { useEffect, useState } from 'react';
import { Jumbotron, Container, Nav } from 'react-bootstrap';
import { Hero, Footer, Hightlights, Features, Header, About, Fees, Gallery, Contact, Events } from '../components';
import { Outlet } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import { useLayout } from '../components/LayoutProvider';

export function Home() {
    const { website, API_URL } = useOutletContext();

    useEffect(() => {
        document.title = "Home | " + website.school_name;
    });

    return (<>
        <Hero />
        <Container >
            <hr className="featurette-divider"/>
            <About/>
            <hr className="featurette-divider"/>
            <Events />
            <hr className="featurette-divider"/>
            <Gallery />
            {(website.school_fees && website.school_fees.components) && <hr className='featurette-divider'/>}
            <Fees />
            <hr className='featurette-divider'/>
            <Contact />
            {/* <hr className="featurette-divider"/> */}
        </Container>
    </>);
  }