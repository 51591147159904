import React, { useRef, useEffect, useLayoutEffect} from 'react';
import { Container } from 'react-bootstrap';
import image1 from '../images/pexels-monstera-9430886.jpg';
import Map from '../components/Map';
import { useOutletContext } from "react-router-dom";

export function Contact () {
  const { website, API_URL } = useOutletContext();

  // console.log("Website", website)
  const [contactForm, setContactForm] = React.useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = React.useState({
    name: null,
    email: null,
    phone: null,
    message: null
  });
  const [submitting, setSubmitting] = React.useState(false);
  const [parentWidth, setParentWidth] = React.useState(300);
  const [formError, setFormError] = React.useState("")

  const mapParentRef = useRef(null);

  const submitContactForm = async () => {
    const formHasError = Object.values(errors).some(error => error !== null)
    const formHasEmptyField = Object.values(contactForm).some(field => field === "")
    if (formHasError || formHasEmptyField) {
      setFormError("There is an error with your form!")
      return
    }

    setFormError("")
    setSubmitting(true);
    fetch(`${API_URL}/contactform`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(contactForm)
    }).then(response => {

      setSubmitting(false);
    }).catch(error => {
      setSubmitting(false);
    });
  }

  useEffect( () => {
    handleResize();

  }, []);


  const handleResize = () => {
    setParentWidth(mapParentRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const validate = (name, value) => {
    const validators= {
      email: {
        match: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 
        error: "Please provide a valid email!"
      },
      phone: {
        match: /^0\d{9}$/,
        error: "Please provide a valid phone number!"
      },
      name: {
        match: /^\s*[a-zA-Z_]+(?:[' -][a-zA-Z_]+)*\s*$/,
        error: "Please provide a valid name!"
      },
      message: {
        match: /^(?!\s*$).+/,
        error: "Please provide a valid message"
      }
    }

    const matches = validators[name].match.test(value)
    if(!matches){
      setErrors(errs => ({...errs, [name]: validators[name].error}))
      console.log("Error: ", validators[name].error)
    } else {
      setErrors(errs => ({...errs, [name]: null}))
    }
  }

  const handleChange = (event) => {
    setFormError("")
    const name = event.target.name;
    const value = event.target.value;

    if (value !== "") validate(name, value)
    else setErrors(errs => ({...errs, [name]: null}))

    setContactForm(values => ({...values, [name]: value.trim()}))
  }


  return (<Container>
    <div className="row featurette" id="contact">
      <div className="col-md-7 col-sm-12 align-top">
        <h2 className="featurette-heading fw-normal lh-1 " style={{marginTop: '0rem', color: website.primary_color}}>Contact <span className="text-muted">Information</span></h2>
        <p className="lead">
          Tel: {website.contact_us.telephone} <br/>
          P.O.Box {website.contact_us.p_o_box} <br/>
          Location: {website.contact_us.location}<br/>

        </p>

        <h2>Fill contact form</h2>


        <form className='mb-5' data-testid="form" >
          <div className="mb-3">
            <label for="contact-name" className="form-label">Name</label>
            <input placeholder='e.g John Doe' name='name' type="text" className="form-control" id="contact-name" aria-describedby="emailHelp" onChange={handleChange}/>
            {errors.name && <span style={{color: "red", fontSize: "15px"}}>{errors.name}</span>}
          </div>

          <div className="row">
            <div className="col">
            <label for="contact-email" className="form-label">Email address</label>
            <input placeholder='e.g name@example.com' name='email' type="email" className="form-control" id="contact-email" aria-describedby="emailHelp" onChange={handleChange}/>
            {errors.email && <span style={{color: "red", fontSize: "15px"}}>{errors.email}</span>}
            </div>
            <div className="col">
            <label for="contact-telephone" className="form-label">Phone number</label>
            <input placeholder='e.g. 0771123456' name='phone' type="tel" className="form-control" id="contact-telephone" aria-describedby="emailHelp" onChange={handleChange}/>
            {errors.phone && <span style={{color: "red", fontSize: "15px"}}>{errors.phone}</span>}
            </div>
          </div>
          <div className="mb-3">
            <label for="contact-message" className="form-label">Message</label>
            <textarea placeholder='Message here' name='message' className="form-control" aria-label="With textarea" id='contact-message' onChange={handleChange}></textarea>
            {errors.message && <span style={{color: "red", fontSize: "15px"}}>{errors.message}</span>}
          </div>
          <button 
            disabled={submitting}
            type="submit" 
            className="btn btn-primary" 
            style={{backgroundColor: website.primary_color, borderWidth: 0}}
            onClick={(e) => { 
              e.preventDefault(); 
              submitContactForm();
          }}>
          {submitting ? <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:null} 
          Submit
        
        </button>
        {formError && <><br/><span style={{color: "red", fontSize: "15px"}}>{formError}</span></>}

    
      </form>


      </div>
      <div className="col-md-5 col-sm-12" ref={mapParentRef} data-testid="map-container">
        <div id="map" style={{width: parentWidth, height: 550}}><Map gps={website.contact_us.gps} width={parentWidth}/></div>
      </div>
    </div>
    </Container>);
}