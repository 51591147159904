import { Nav } from "react-bootstrap";
import { useOutletContext } from "react-router-dom";
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationArrow,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram, faTwitter, faWhatsapp, faLinkedin} from "@fortawesome/free-brands-svg-icons";

function Company({website}){
    const styles = {
        
    }
    return <div style={styles} className='copyright'>
        <p className="float-end"><a href="#" style={{color: website.primary_color || ""}}>Back to top</a></p>
        <p style={{color: "white"}}>&copy; {(new Date()).getFullYear()} Bodastage Solutions &middot; <a href="/privacy" style={{color: website.primary_color}}>Privacy</a> &middot;</p>
    </div>
}

export function Footer (props) {
    const { website } = props;
    const socials = website.socials && Object.keys(website.socials)
    const links =website.socials &&  Object.values(website.socials)

    const icons = {
        facebook: faFacebook,
        twitter: faTwitter,
        whatsapp: faWhatsapp,
        linkedin: faLinkedin,
        instagram: faInstagram
    }
    
    return <>
        <div className="featurette-divider"></div>
        <footer className="" data-testid="footer">
            <div className='row'>
                {/* <div className='col-md-4 col-sm-12'>
                    <img src={website.badge_url || "https://esomelo.com/static/logo%20william.3f035876.png"} alt="School badge" width="300" height="300" style={{ marginRight: "20px", marginLeft: "20px" }} />
                </div> */}
                <div className='col-md-4 col-sm-12' style={{color: "#ffffff8c"}}>
                    <h3 style={{color: "white", fontWeight: "bold"}}>Important links</h3>
                    <Nav className="flex-column footer-nav">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/#about">About us</Nav.Link>
                        <Nav.Link href="/#about">Events</Nav.Link>
                        <Nav.Link href="/apply">Apply</Nav.Link>
                        <Nav.Link href="/gallery">Gallery</Nav.Link>
                    </Nav>
                </div>
                <div className='col-md-4 col-sm-12' style={{color: "#ffffff8c"}}>
                    <h3 style={{color: "white", fontWeight: "bold"}}>Contact us</h3>
                    <p className="lead">
                        <FontAwesomeIcon icon={faPhone} color={website.primary_color} />&nbsp;Tel: {website.contact_us.telephone} <br/>
                        <FontAwesomeIcon icon={faEnvelope} color={website.primary_color}/>&nbsp;P.O.Box {website.contact_us.p_o_box} <br/>
                        <FontAwesomeIcon icon={faLocationArrow} color={website.primary_color} />&nbsp;Location: {website.contact_us.location}<br/>
                    </p>

                    <p className="lead footer-socials">
                        {
                            socials?.map((social, index) => {
                                const link = links[index]
                                if(link){
                                    return <span key={index}><a href={link}><FontAwesomeIcon icon={icons[social]} /></a></span>
                                }
                            })
                        }
                    </p>
                </div>

            </div>
        </footer>
        <Company website={website}/>
    </>;
}