import React, { useState, useRef, useLayoutEffect } from 'react';
import { Container, Nav } from 'react-bootstrap';
import {
    useLoaderData
  } from "react-router-dom";

export function Header(props) {
    const { website } = props;
    const ref = useRef(null);

    useLayoutEffect(() => {
        ref.current.style.setProperty("background-color", website.primary_color, "important");
    }, []);

    return (<>
        <header>
            <Nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark" ref={ref}>
                <div className="container-fluid container-xxl">
                    <a className="navbar-brand" href="/">
                        <img src={website.badge_url || "https://esomelo.com/static/logo%20william.3f035876.png"} alt="School badge" width="40" height="40" style={{ marginRight: "20px", marginLeft: "20px" }} />
                        {website.school_name ? website.school_name: 'Loading....'}
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarCollapse" data-testid="nav-menu">
                        <ul className="navbar-nav ms-md-auto mb-2 mb-md-0 justify-content-end">
                            <li className="nav-item">
                                {/* TODO: change color of active link during navigation */}
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#about">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#events">Events</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/apply">Apply</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/gallery">Gallery</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/elections">Elections</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/#contact">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Nav>
        </header>
    </>);
}